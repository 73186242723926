import React, { createContext, useContext, ReactNode } from 'react';

interface ComponentContextProps {
	componentName: string;
	componentId: string;
}

const ComponentContext = createContext<ComponentContextProps | undefined>(
	undefined
);

export type CWPProps<T> = T & { context: ComponentSSRContext };

export interface ComponentContext {
	componentName: string;
	componentId: string;
}

export interface ComponentSSRContext extends ComponentContext {
	oneBlkSSRData?: {
		libPath: string;
		cssPaths: Record<string, string[]>;
	};
}

interface ComponentProviderProps {
	context: ComponentContext;
	children: ReactNode;
}

/**
 * ComponentProvider component provides the ComponentContext to its children, containing basic information from CWP.
 * Available in SSR and CSR context as well.
 */
export const ComponentProvider: React.FC<ComponentProviderProps> = ({
	context,
	children,
}) => {
	const { componentName, componentId } = context;
	return (
		<ComponentContext.Provider value={{ componentName, componentId }}>
			{children}
		</ComponentContext.Provider>
	);
};

export const useComponentContext = (): ComponentContextProps => {
	const context = useContext(ComponentContext);
	if (!context) {
		throw new Error(
			'useComponentContext must be used within a ComponentProvider'
		);
	}
	return context;
};
