import {
	Dispatch,
	MutableRefObject,
	SetStateAction,
	useRef,
	useState,
} from 'react';

const isFunction = <T>(
	setStateAction: SetStateAction<T>
): setStateAction is (prevState: T) => T => {
	return typeof setStateAction === 'function';
};

/**
 * Since React State is immutable,
 * sometimes you may need a (mutable) reference with the current value of State,
 * e.g. when you use State in a callback (to setTimeout or window event listener).
 * @param {undefined | T | (() => T)} initialValue - The initial value of the State.
 * @returns {T} state - The current state. (Immutable!)
 * @returns {MutableRefObject<T>} stateRef - Mutable Reference with the current state.
 * @returns {Dispatch<SetStateAction<T>>} setState - The set function for the state.
 */
export const useStateRef = <T>(
	initialValue?: T | (() => T)
): [T, MutableRefObject<T>, Dispatch<SetStateAction<T>>] => {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const [state, _setState] = useState<T>(initialValue);
	const stateRef = useRef<T>(state);

	const setState: Dispatch<SetStateAction<T>> = (
		setStateAction: T | ((prevState: T) => T)
	) => {
		stateRef.current = isFunction(setStateAction)
			? setStateAction(stateRef.current)
			: setStateAction;
		_setState(stateRef.current);
	};

	return [state, stateRef, setState];
};
